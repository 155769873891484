<template>
	<div>
		<van-checkbox class="checkbox" v-model="rule_1" shape="square" icon-size="0.28rem" label-disabled>
			<span @click="rule_1 = !rule_1">本人已充分理解并同意</span>
			<span class="is_link" @click="seeRules">《保险条款》</span>
			<span class="is_link" @click="seeRule('投保声明')">《投保声明》</span>
			<br />
			<span class="is_link per_rule" @click="seeRule('个人信息保护政策')">《个人信息保护政策》</span>
		</van-checkbox>
		<van-checkbox class="checkbox" v-model="rule_2" shape="square" icon-size="0.28rem" @click="rule_2_check">
			本人已充分理解并同意
			<span class="is_link">《免责内容》</span>
			<span class="is_link">《被保险人同意声明》</span>
			<br />
			<span class="is_link per_rule">《投保提示》</span>
		</van-checkbox>
		<bottom-info-pop v-model="infoPopShow" :title="infoPopTitle" :content="infoPopContent"></bottom-info-pop>

		<van-action-sheet v-model="mustReadPopShow" class="must_read_pop" title="请确认以下内容">
			<div class="must_read_content">
				<div class="tabs">
					<div class="tab" :class="{ active: mustReadIndex === 1 }">
						<span class="text">《免责内容》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 2 }">
						<span class="text">《被保险人同意声明》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 3 }">
						<span class="text">《投保提示》</span>
						<span class="icon"></span>
					</div>
				</div>
				<div class="content">
					<div class="content_info" v-show="mustReadIndex === 1" v-html="information.preventDuty">免责内容</div>
					<div class="content_info" v-show="mustReadIndex === 2" v-html="information.insuredDeclare">被保险人同意声明</div>
					<div class="content_info" v-show="mustReadIndex === 3" >
						<template v-if="Array.isArray(information.insuranceTip)">
							<div v-for="(item,index) in information.insuranceTip" :key="index">
								<div class="hint_title pv_10" v-html="item.title"></div>
								<div v-html="item.content"></div>
							</div>
						</template>
						<div v-else>
							<div v-html="information.insuranceTip">投保提示</div>
						</div>
						
					</div>
				</div>
				<div class="loading_btn_box">
					<van-button class="loading_btn" @click="nextDoc" :disabled="isReading" type="info" color="#2594EF" block="" size="normal">
						<div class="loading_btn_content">
							<span v-show="mustReadIndex === 1">已阅读并同意《免责内容》</span>
							<span v-show="mustReadIndex === 2">已阅读并同意《被保险人同意声明》</span>
							<span v-show="mustReadIndex === 3">已阅读并同意《投保提示》</span>
							<van-count-down v-show="isReading" ref="countDown" class="time" :time="0" @finish="finish">
								<template #default="timeData">
									<span class="block">{{ timeData.seconds }}秒</span>
								</template>
							</van-count-down>
						</div>
					</van-button>
				</div>
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
import { ActionSheet, Button, checkbox, countDown } from 'vant';
export default {
	components: {
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[checkbox.name]: checkbox,
		[countDown.name]: countDown,
	},
	props: {
		proId: {
			type: [String, Number],
		},
		comboId: {
			type: [String, Number],
		},
		insProductInformation2Vo: {
			type: Object,
			default: () => {
				return {
					f1FixedInfo: '', //投保声明,
					f3FixedInfo: '', //个人信息保护政策
					preventDuty: '', //免责内容
					f2FixedInfo: '', //被保险人同意声明
					hint: '', //投保提示
				};
			},
		},
		platform:{
			type:String,
			default:""
		}
	},
	data() {
		return {
			rule_1: false,
			infoPopShow: false, //投保声明和个人信息保护弹框
			infoPopContent: '', //投保声明和个人信息保护内容
			infoPopTitle: '', //投保声明和个人信息保护标题
			rule_2: false,
			mustReadPopShow: false,
			isReading: false,
			mustReadIndex: 0,
			information: {},
		};
	},
	watch: {
		allOk(newValue) {
			this.$emit('getReadOk', newValue);
		},
	},
	computed: {
		allOk() {
			return this.rule_2 && this.rule_1;
		},
	},
	methods: {
		// 去保障条款页面
		seeRules() {
			let routeName="safeguardClause"
			if(this.platform=='cps'){
				routeName='cpsSafeguardClause'
			}
			this.$router.push({
				name: routeName,
				params: {
					productId: this.proId,
					comboId: this.comboId?this.comboId:"",
				},
			});
		},
		// 投保声明和个人信息保护策略
		seeRule(title) {
			this.infoPopTitle = title;
			if (title === '投保声明') {
				this.infoPopContent = this.insProductInformation2Vo.f1FixedInfo;
			}
			if (title === '个人信息保护政策') {
				this.infoPopContent = this.insProductInformation2Vo.f3FixedInfo;
			}
			this.infoPopShow = true;
		},
		//第二条条款方法
		rule_2_check() {
			const { preventDuty, f2FixedInfo, hint } = this.insProductInformation2Vo;
			this.information = {
				preventDuty:this.$store.state.insuranceV2.preventDuty||preventDuty,
				insuredDeclare: f2FixedInfo,
				insuranceTip: this.$store.state.insuranceV2.hint?.length>0?this.$store.state.insuranceV2.hint:hint,
			};
			if (this.rule_2) {
				this.rule_2 = false;
				this.mustReadIndex = 1;
				this.isReading = true;
				this.mustReadPopShow = true;
				this.$nextTick(() => {
					this.$refs.countDown.reset();
				});
			}
		},
		nextDoc() {
			if (this.mustReadIndex < 3) {
				this.mustReadIndex++;
				this.isReading = true;
				this.$refs.countDown.reset();
			} else {
				this.rule_2 = true;
				this.mustReadPopShow = false;
			}
		},
		finish() {
			this.isReading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .checkbox {
	margin: 0.5rem 0.3rem;
	align-items: flex-start;
	.van-checkbox__icon {
		position: relative;
		top: 0.06rem;
		border-radius: 0.06rem;
		.van-icon {
			border-radius: 0.06rem;
		}
		padding-right: 0.2rem;
	}
	.van-checkbox__label {
		font-size: 0.24rem;
		line-height: 0.36rem;
		color: $font_color_thd;
		margin-left: 0;
	}
}
.is_link {
	color: $color_main;
}
::v-deep .must_read_pop {
	.van-action-sheet__content {
		overflow: hidden;
	}
}
.must_read_content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 0 0.3rem 0.3rem;
	height: 70vh;
	box-sizing: border-box;
	.tabs {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.5rem;
		.tab {
			display: flex;
			flex-direction: column;
			align-items: center;
			.text {
				font-size: 0.28rem;
				line-height: 0.36rem;
				color: $font_color_val;
			}
			.icon {
				width: 0.3rem;
				height: 0.06rem;
				margin-top: 0.16rem;
			}
		}
		.active {
			.text {
				color: $color_main;
			}
			.icon {
				background-color: $color_main;
			}
		}
	}
	.content {
		flex: 1;
		overflow-y: auto;
		font-size: 0.24rem;
	}
	.loading_btn_box {
		width: 100%;
		padding: 0.2rem 0.3rem;
		box-sizing: border-box;
		.loading_btn {
			border-radius: 8px;
			height: 0.8rem;
		}
	}
	.content_info {
		word-break: break-all;
	}
}
.must_read_pop {
	z-index: 3001 !important;
	.loading_btn_content {
		display: flex;
		align-items: center;
		.time {
			color: #fff;
			margin-left: 0.4rem;
		}
	}
}
.hint_title{
	font-weight: bold;
}
.pv_10{
	padding: 10px 0;
}
</style>
