<template>
	<div>
		<liCreateOrderV2 />
	</div>
</template>

<script>
import liCreateOrderV2 from '../../../liability/v2/liCreateOrderV2.vue';
export default {
	name: 'cpsLiCreateOrderV2',
	components: {
		liCreateOrderV2,
	},
	beforeRouteLeave(to, from, next) {
		this.scrollY = document.querySelector('html').scrollTop;
		if (to.name === 'cpsLiProduct') {
			const store = from.matched[0].instances.default.$store;
			store.commit('set_cps_keepLiveCom', 'cpsHome,cpsProduct');
		}
		next();
	},
	beforeRouteEnter(to, from, next) {
		if (from.name === 'cpsLiProduct' || from.name === 'cpsOrderPay') {
			const store = from.matched[0].instances.default.$store;
			store.commit('set_cps_keepLiveCom', 'cpsHome,cpsLiCreateOrderV2,cpsProduct');
		}
		next(vm => {
			vm.$nextTick(() => {
				document.querySelector('html').scrollTop = vm.scrollY;
			});
		});
	},
};
</script>

<style lang="scss" scoped></style>
