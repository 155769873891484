// v2版本，表单的校验方法
import moment from 'moment/moment';
import Mtils from 'mtils';
import store from '@/store';


export default {
	// 根据出生日期校验是否为成年人
	birthdayIsAdult(value) {
		let diff = moment(store.state.insuranceV2.startTime).diff(value, 'years');
		if (diff < 18) {
			return false
		} else {
			return true
		}
	},

	// 身份证号码校验
	identityCardNumber(value) {
		
		if (Mtils.validation.isIdCard(value)) {
			return true
		} else {
			return false
		}
	},
	/**
	 * 多个字段必须有一个有值
	 * @param {*} id 当前选择的id
	 * @param {*} allFormId 所有form值
	 * @param {*} filedList 字段数据
	 * @returns 
	 */

	multipleFieldMinSelect(value,rule){
		const allFormId=store.state.insuranceV2.allFormId
		const filedList=store.state.insuranceV2.formOptions

		let flag=false
		let multipleFieldMinSelect={}
		for(let key in filedList){
			filedList[key].rule?.forEach(v=>{
				if(v.ruleType === 'validation' && v.validation.indexOf('multipleFieldMinSelect')!==-1){
					if(multipleFieldMinSelect[v.validation]){
						multipleFieldMinSelect[v.validation].push(filedList[key])
					}else{
						multipleFieldMinSelect[v.validation]=[filedList[key]]
					}
				}
			})
		}
		multipleFieldMinSelect[rule.validation]?.forEach((item)=>{
			if((Array.isArray(allFormId[item.prop]) && allFormId[item.prop].length>0) || (!Array.isArray(allFormId[item.prop])&&allFormId[item.prop])){
				flag=true
			}
		})
		return flag
	}
};
