<template>
	<div>
		<van-collapse v-model="activeNames">
			<van-collapse-item name="1" :border="false" :lazy-render="false">
				<template #title>
					<div class="flex_button">
						<active-title>{{ group.groupName }}</active-title>
						<div class="user_same" v-if="showSame" @click.stop="sameTbr">同投保人</div>
					</div>
				</template>

				<formGroupItem v-if="showForm" ref="form" :form-option="formOption" :rules="rule" v-model="form">
					<template v-if="showPlan" #header>
						<!-- 旅险 -->
						<plan ref="planForm" v-if="noLiability" :fixedEnableChange="fixedEnableChange" :fiexdEnableDate="fiexdEnableDate"></plan>
						<!-- 责任险 -->
						<liabilityPlan v-else ref="liabilityPlan" />
					</template>
				</formGroupItem>
				<van-button type="primary" @click="getData" class="none">获取数据</van-button>
			</van-collapse-item>
		</van-collapse>
	</div>
</template>

<script>
import formGroupItem from '@/components/formGroupItem.vue';
import plan from './plan.vue';
import { Button, Collapse, CollapseItem } from 'vant';
import liabilityPlan from '../../liability/v2/components/liabilityPlan.vue';
import validations from '@/assets/js/validations.js';
import { http_insTranslate } from '@/request/common.js';
import Mtils from 'mtils';
export default {
	components: {
		formGroupItem,
		plan,
		liabilityPlan,
		[Button.name]: Button,
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
	},
	props: {
		group: {
			type: Object,
			default: () => {},
		},
		// 默认保障期限是否可以修改 0-不可改 1-可改
		fixedEnableChange: {
			type: Number,
			required: false,
			default: 1,
		},

		// 是否固定保障时间  true-固定  false-不固定
		fiexdEnableDate: {
			type: Boolean,
			required: false,
			default: false,
		},
		// disabled
		fixedDisabled: {
			type: Boolean,
			default: false,
		},
	},
	inject: ['productInfo'],
	data() {
		return {
			activeNames: ['1'],
			groupList: [],
			specialDom: ['protectionPerido'],
			form: {},
			rule: {},
			fieldRealtion: {},
			formOption: [],
			// 需要使用code的组件类型
			codeDom: ['selectSingle', 'selectMultiple', 'radio', 'checkbox','singleCheckbox'],

			showForm: false,
			allFormOptions: [], //所有options
			showPlan: false,
			insTypeId: '', //获取投保人类型id
			// 根据身份证号码计算出生日期与性别
			idCardNeedAnalysis: false,
			idCardLastValue: undefined, // 上一次证件号码的值
			idCardSexKey: undefined,
			idCardBirthdayKey: undefined,
			idCardKey: undefined,
			hasEnglishName: false, //是否有英文名
			lastName: '', //保存上一次姓名
		};
	},
	watch: {
		form: {
			handler(val) {
				// 改变formOptions
				this.formOption = this.allFormOptions.filter(item => this.fieldsShow[String(item.prop)]);
				// 根据身份证号码计算性别与出生日期
				this.getSexAndBirthdayByIdCard();
					//this.handleTranslate();

				const obj = {};
				const objId = {};
				for (let i in val) {
					this.group.insForm2VoList.forEach(item => {
						if (item.id == i) {
							obj[item.fieldName] = val[i];
							objId[item.id] = val[i];
						}
					});
				}
				this.$store.commit('setAllForm', obj);
				this.$store.commit('setAllFormId', objId);
			},
			deep: true,
		},
		// 监听投保人类型
		formInsure(newValue) {
			this.$store.commit('setShowCommon', newValue);
		},
	},
	computed: {
		showSame(){
			return this.group.groupCode==="assured"&&!this.noLiability
		},
		// 计算字段是否显示，返回一个对象，存储所有字段的显示方案
		fieldsShow() {
			let result = {};
			for (const key in this.fieldRealtion) {
				if (Object.hasOwnProperty.call(this.fieldRealtion, key)) {
					const field = this.fieldRealtion[key];
					if (field.parentId && field.parentValue) {
						// 父级字段
						let parentField = this.fieldRealtion[String(field.parentId)];
						if (parentField) {
							let parentValues = JSON.parse(field.parentValue);
							let parentValue = this.form[String(field.parentId)];
							if (result[String(field.parentId)] && (parentValues.includes(Number(parentValue)) || parentValues.includes(String(parentValue)))) {
								result[key] = true;
							} else {
								result[key] = false;
							}
						} else {
							result[key] = true;
						}
					} else {
						result[key] = true;
					}
				}
			}

			this.getIsNeedGetSexAndBirthdayByIdCard(this.fieldRealtion, result);
			return result;
		},
		codeDict() {
			let result = {};
			for (const id in this.fieldRealtion) {
				if (Object.hasOwnProperty.call(this.fieldRealtion, id)) {
					const field = this.fieldRealtion[id];

					if (this.codeDom.includes(field.displayType)) {
						// 字段中配置的可选代码，值为 code下的commonCode字段
						let enableValues = JSON.parse(field.optionalValue);

						// 所有该分组下的可选code
						let allCode = this.$store.state.insuranceV2.codeList.filter(item => item.category === field.optionalGroup && enableValues.includes(item.commonCode));

						// 最终可选的code列表
						let resultList = [];

						// 判断code是否有父级关联关系
						allCode.forEach(item => {
							if (item.parentId && item.parentValue) {
								// code字段中配置的父级code的选择值,只有父级code的这些值被选中了，该code才允许被选择
								let parentValues = item.parentValue.split(',');

								// 根据父级分组id寻找父级code
								let parentCodeList = this.$store.state.insuranceV2.codeCategory.filter(p => String(p.id) === String(item.parentId));
								if (parentCodeList.length) {
									let parentCode = parentCodeList[0];

									// 根据父级code寻找使用该code的字段(该父级不一定存在)
									let parentFieldList = this.groupList.filter(p => p.optionalGroup === parentCode.code);
									if (parentFieldList.length) {
										let parentField = parentFieldList[0];

										// 给父级字段加上子级，在父级字段的值改变时要清空子级
										if (result[String(parentField.id)]) {
											if (result[String(parentField.id)].children) {
												result[String(parentField.id)].children.push(id);
											} else {
												result[String(parentField.id)].children = [id];
											}
										} else {
											result[String(parentField.id)] = {
												list: [],
												children: [id],
											};
										}
										// 根据字段信息，去拿该字段组件所选的值
										let value = this.form[String(parentField.id)];
										if (value) {
											if (Array.isArray(value)) {
												if (value.some(v => parentValues.includes(v))) {
													resultList.push(item);
												}
											} else {
												if (parentValues.includes(value)) {
													resultList.push(item);
												}
											}
										} else {
											// 如果父级还没选择，那就先不给值吧，免得用户看见子级能选，选了父级后又不能选了
										}
									} else {
										// 使用父级code的字段都不存在，那么就对该code无影响
										resultList.push(item);
									}
								}
							} else {
								resultList.push(item);
							}
						});

						if (result[id]) {
							result[id].list = resultList;
						} else {
							result[id] = {
								list: resultList,
							};
						}
					}
				}
			}
			return result;
		},
		// 投保人类型
		formInsure() {
			return this.form[this.insTypeId];
		},
		// 是否是责任险
		noLiability() {
			return this.productInfo().productCategoryFirst != 2;
		},
	},

	async created() {
		this.getInitData();
		if (this.group.groupCode === 'insure') {
			this.insTypeId = this.getInsType('insType');
		}
	},
	methods: {
		//获取初始数据
		async getInitData() {
			const group = this.group.insForm2VoList;
			const list = group.sort((a, b) => {
				return a.orderNo - b.orderNo;
			});
			this.groupList = list;
			this.showPlan = list.filter(item => item.displayType == 'protectionPerido').length > 0;
			this.setFormField(list);
		},
		//设置form字段
		setFormField(list) {
			let form = {};
			let rule = {};
			let fieldRealtion = {};
			list.forEach(item => {
				if (!this.specialDom.includes(item.displayType)) {
					form[String(item.id)] = JSON.parse(item.defaultValue);
					let ruleList = JSON.parse(item.validateRules);
					let newRuleList = ruleList.map(ruleItem => {
						// 正则校验
						if (ruleItem.ruleType === 'pattern') {
							ruleItem.pattern = this.stringToRegExp(ruleItem.pattern);
						}
						// 方法校验
						if (ruleItem.ruleType === 'validation') {
							if (ruleItem.validation.indexOf('multipleFieldMinSelect') !== -1) {
								ruleItem.validator = validations.multipleFieldMinSelect;
							} else {
								ruleItem.validator = validations[ruleItem.validation];
							}
						}
						// 把blur、change改成onBlur和onChange
						ruleItem.trigger = `on${ruleItem.trigger.slice(0, 1).toUpperCase()}${ruleItem.trigger.slice(1, ruleItem.trigger.length)}`;
						if (ruleItem.ruleType === 'required') {
							delete ruleItem.message;
						}
						return ruleItem;
					});
					rule[String(item.id)] = newRuleList;
					fieldRealtion[String(item.id)] = item;
					if (item.fieldName == 'insEnglishName') {
						this.hasEnglishName = true;
					}
				}
			});

			this.$set(this, 'form', form);
			this.$set(this, 'rule', rule);
			this.$set(this, 'fieldRealtion', fieldRealtion);

			this.changeFormOptions();
		},
		// 把字符串转化为正则对象
		stringToRegExp(patternString) {
			let lastCharacter = patternString.charAt(patternString.length - 1);
			if (['i', 'g', 'm'].includes(lastCharacter)) {
				return new RegExp(patternString.slice(1, patternString.length - 2), lastCharacter);
			} else {
				return new RegExp(patternString.slice(1, patternString.length - 1));
			}
		},

		//翻译姓名
		async handleTranslate() {
			if (this.hasEnglishName) {
				const field = this.groupList.filter(item => item.fieldName === 'insEnglishName');
				if (field.length && this.fieldsShow[String(field[0].id)]) {
					const insName = this.groupList.filter(item => item.fieldName === 'insName' && this.fieldsShow[String(item.id)])[0];
					if (insName && this.form[String(insName.id)]) {
						const data = await http_insTranslate([this.form[String(insName.id)]]);
						if (this.lastName != this.form[String(insName.id)]) {
							this.$set(this.form, field[0].id, data[this.form[String(insName.id)]] || '');
							this.lastName = this.form[String(insName.id)];
						}
					}
					if (!this.form[String(insName.id)] && this.lastName != this.form[String(insName.id)]) {
						this.$set(this.form, field[0].id, '');
					}
				}
			}
		},

		// 处理formOptions
		changeFormOptions() {
			let formOptions = [];
			const storeData = this.$store.state.insuranceV2;
			let allFormOption = {};
			this.groupList.forEach(item => {
				const options = {
					label: item.showName,
					prop: String(item.id),
					placeholder: item.placeholder,
					type: item.displayType,
					rule: this.rule[item.id],
					hide: item?.hide,
					disabled:false
				};
				// 数字输入框
				if (item.displayType === 'inputNumber') {
					options.inputType = 'number';
				}
				// 如果是地区或者是省市区
				if (item.displayType === 'provinceCityArea') {
					options.type = 'cascader';
					options.options = this.getCascader(storeData.areaList, { name: 'areaName', code: 'areaCode' });
				}
				// 如果是多选框
				if (item.displayType === 'checkbox') {
					options.type = 'selectMultiple';
				}
				//文本框
				if (item.displayType === 'textarea') {
					options.inputType = 'textarea';
				}
				// 如果是职业
				if (item.displayType === 'category') {
					options.type = 'cascader';
					const careerData = this.filterTree(storeData.careerList, JSON.parse(item.optionalValue));
					options.options = this.getCascader(careerData, { name: 'professionName', code: 'professionCode' });
					options.cascaderShow = 'last'; //只展示最后一个
				} else if (item.displayType === 'country') {
					options.type = 'moreSelect';
					options.options = storeData.countryList
						.filter(v => JSON.parse(item.optionalValue).includes(v.code))
						.map(v => {
							return { label: v.name, name: v.code };
						});
				} else if (['selectSingle', 'radio'].includes(item.displayType)) {
					options.type = 'picker';
				} else if (item.displayType === 'date') {
					if (item.fieldName === 'insBirthday') {
						options.type = 'datetimePicker';
					} else {
						options.type = 'calendar';
					}
				}
				if (this.codeDom.includes(item.displayType)) {
					options.options = this.codeDict[String(item.id)]?.list.map(v => {
						return {
							name: v.commonCode,
							label: v.commonDeclare,
						};
					});
				}

				// 隐藏固定投保人和常用联系人
				if(item.fieldName=='fixedFlag'||item.fieldName=='saveContactsFlag'){
					options.hide=1
				}
				// 发票disabled
				if(item.fieldName=='invoiceHeader'){
					options.disabled=true
				}
				// disabled

				if (this.fixedDisabled) {
					this.activeNames = ['0'];
					options.readonly = this.fixedDisabled;
				}
				formOptions.push(options);
				allFormOption[item.id] = options;
			});
			this.allFormOptions = formOptions;
			this.formOption = formOptions.filter(item => this.fieldsShow[String(item.prop)]);
			this.showForm = true;
			this.$store.commit('setFormOptions', allFormOption);
			
		},

		//获取投保类型id
		getInsType(field) {
			return this.group?.insForm2VoList.filter(item => item.fieldName === field)[0]?.id;
		},
		// 计算是否需要根据身份证号码来计算性别与出生日期
		getIsNeedGetSexAndBirthdayByIdCard(fieldDict, showDict) {
			// insCredentials:证件号码
			// insSex:性别
			// insBirthday:出生日期
			let fieldIdDict = {};
			for (const key in fieldDict) {
				if (Object.hasOwnProperty.call(fieldDict, key)) {
					const value = fieldDict[key];
					if (showDict[key]) {
						fieldIdDict[value.fieldName] = key;
					}
				}
			}

			let keys = Object.keys(fieldIdDict);
			if (keys.includes('insCredentials') && (keys.includes('insSex') || keys.includes('insBirthday'))) {
				this.idCardNeedAnalysis = true;
				this.idCardKey = fieldIdDict.insCredentials;
				if (keys.includes('insSex')) {
					this.idCardSexKey = fieldIdDict.insSex;
				}
				if (keys.includes('insBirthday')) {
					this.idCardBirthdayKey = fieldIdDict.insBirthday;
				}
			} else {
				this.idCardNeedAnalysis = false;
				this.idCardSexKey = undefined;
				this.idCardBirthdayKey = undefined;
			}
		},
		// 特殊处理-根据身份证号码计算出生日期与性别
		getSexAndBirthdayByIdCard() {
			if (this.idCardNeedAnalysis) {
				let idCard = this.form[this.idCardKey];
				if (idCard !== this.idCardLastValue) {
					// 执行计算
					if (idCard) {
						if (this.idCardBirthdayKey && Mtils.validation.isIdCard(idCard)) {
							this.$set(this.form, this.idCardBirthdayKey, this.$base.getInfoFromIdNumber(idCard).birthday);
						}
						if (this.idCardSexKey && Mtils.validation.isIdCard(idCard)) {
							this.$set(this.form, this.idCardSexKey, this.$base.getInfoFromIdNumber(idCard).sex);
						}
					} else {
						if (this.idCardBirthdayKey) {
							this.$set(this.form, this.idCardBirthdayKey, '');
						}
						if (this.idCardSexKey) {
							this.$set(this.form, this.idCardSexKey, '');
						}
					}
					this.idCardLastValue = idCard;
				}
			}
		},

		// 处理级联选择器
		getCascader(obj, fieldName) {
			// 检查对象是否为数组
			if (Array.isArray(obj)) {
				for (let i = 0; i < obj.length; i++) {
					obj[i] = this.getCascader(obj[i], fieldName);
				}
			} else if (typeof obj === 'object' && obj !== null) {
				for (let key in obj) {
					if (obj.hasOwnProperty(key)) {
						if (key === fieldName['name']) {
							obj['name'] = obj[key];
							delete obj[key];
						} else if (key === fieldName['code']) {
							obj['code'] = obj[key];
							delete obj[key];
						} else {
							obj[key] = this.getCascader(obj[key], fieldName);
						}
					}
				}
			}
			return obj; // 返回替换后的对象
		},
		// 过滤数据
		filterTree(treeData, codeList) {
			let t = JSON.parse(JSON.stringify(treeData));
			function traverseTree(tree, codes, parent = null) {
				for (let i = tree?.length - 1; i >= 0; i--) {
					const node = tree[i];
					if (node?.children && node.children.length) {
						// 存在子节点
						// 继续判断子节点
						traverseTree(node.children, codes, tree);
					} else {
						if (!codes.includes(node?.professionCode)) {
							tree.splice(i, 1);
							traverseTree(parent, codes);
						}
					}
				}
			}
			traverseTree(t, codeList);

			return t;
		},

		// 获取表单数据
		getData() {
			return new Promise(resolve => {
				this.getProductData().then(product => {
					this.$refs.form.handleData().then(() => {
						const formData = JSON.parse(JSON.stringify(this.form));

						let result = {};
						for (const id in formData) {
							const data = this.formOption.filter(item => item.prop === id);
							if (data[0]?.cascaderShow === 'last' && formData[id].indexOf('&') !== -1) {
								formData[id] = formData[id].slice(formData[id].lastIndexOf('&') + 1);
							}
							// 不展示的字段不返回，因为有可能有重复的字段
							if (Object.hasOwnProperty.call(formData, id) && this.fieldsShow[id]) {
								const value = formData[id];
								result[this.fieldRealtion[id].fieldName] = value;
							}
							if(isNaN(formData[id])&&!isNaN(Date.parse(formData[id]))){
								result[this.fieldRealtion[id].fieldName]=formData[id].replace(/\//g, '-')
							}
						}
						if (product) {
							resolve({ product, insurance: result });
						} else {
							if(Object.hasOwnProperty.call(result, 'fixedFlag')&&!result.fixedFlag){
								result.fixedFlag=0
							}
							if(Object.hasOwnProperty.call(result, 'saveContactsFlag')&&!result.saveContactsFlag){
								result.saveContactsFlag=0
							}
							resolve(result);
						}
					});
				});
			});
		},

		// 同投保人
		sameTbr() {
			this.$emit('getInsureForm', this.sameTbrData);
		},
		async sameTbrData(data) {
			let applicantForm = JSON.parse(JSON.stringify(data));
			let addObj = {
				name: applicantForm.insName,
				certificateType: applicantForm.insCredentialsType,
				certificateContent: applicantForm.insCredentials,
				birthday: applicantForm.insBirthday,
				sex: applicantForm.insSex,
				phone: applicantForm?.insPhone,
				englishName: applicantForm?.insEnglishName,
				address:applicantForm?.insAddress,
				workplace:applicantForm?.insBillInsureAddress
			};
			this.group.insForm2VoList.forEach(item=>{
				if(Object.keys(addObj).includes(item.fieldName)&&this.fieldsShow[String(item.id)]){
					this.form[item.id]=addObj[item.fieldName]
				}
			})
			
		},
		// 先获取产品信息数据
		getProductData() {
			return new Promise(resolve => {
				if (this.groupList.some(item => item.displayType === 'protectionPerido')) {
					if (this.noLiability) {
						this.$refs.planForm.getData().then(res => {
							resolve(res);
						});
					} else {
						this.$refs.liabilityPlan.getData().then(res => {
							resolve(res);
						});
					}
				} else {
					resolve(undefined);
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.none {
	display: none;
}
::v-deep .van-collapse-item__content {
	padding: 0;
	color: inherit;
}
::v-deep .van-collapse-item__title {
	padding-left: 0;
}
::v-deep .van-cell::after {
	border: 0;
}
::v-deep .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
	border: 0;
}
::v-deep .van-radio--horizontal {
	padding: 0.04rem 0;
}
::v-deep .van-field__label {
	color: #8b9299;
}
.flex_button{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.user_same {
	padding: 0 0.1rem;
	font-size: 0.2rem;
	text-align: center;
	border-radius: 0.08rem;
	border: 1px solid $color_main;
	color: #fff;
	background-color: $color_main;
}
</style>
